import React, { useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from '@formspree/react';
// import ReactDOM from "react-dom";
// import ReCAPTCHA from "react-google-recaptcha";


// const TEST_SITE_KEY = "6LfYWnMfAAAAAGAo8GJOu05I3Xvrcq7bKdHTSPKS";
// const DELAY = 1500;
// let rerenders = 0;

const ContactForm = (props) => {
    const [state, handleSubmit] = useForm("FORMSPREE_FORM_ID");
    const frm = useRef(null);
    const result = useRef(null);

    if (state.succeeded) {
        frm.current.style.display = "none";
        result.current.style.display = "block";
    }
    // const ReCAPTCHAForm = (props) => {
    //     const recaptchaRef = React.useRef();
       
    //     const onSubmitWithReCAPTCHA = async () => {
    //       const token = await recaptchaRef.current.executeAsync();
       
    //       // apply to form data
    //     }
    //     return (
    //         <form onSubmit={onSubmitWithReCAPTCHA}>
    //           <ReCAPTCHA
    //             ref={recaptchaRef}
    //             size="invisible"
    //             sitekey="Your client site key"
    //           />
    //         </form>
    //       )
    //     }
    // function onChange(value) {
    //     console.log("Captcha value:", value);
    //   }
    //   ReactDOM.render(
    //     <ReCAPTCHA
    //       sitekey="Your client site key"
    //       onChange={onChange}
    //     />,
    //     document.body
    //   );
    return (
        <section id="contact" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				<Row>
					
					{/* <!-- Contact info --> */}
					<div className="contact-info col-12 col-lg-6 res-margin">
						
                        {/* <!-- Section title --> */}
						<div className="section-title text-center text-lg-start">
							<h3>Looking for an awesome business idea?</h3>
							<p>
								Nam erat orci, dictum eu iaculis a, scelerisque commodo risus. 
								Mauris eu egestas ipsum. In hac habitasse platea dictumst. 
								Duis in consequat finibus est.
							</p>
						</div>
						
                        <h5>
                            <span className="icon icon-basic-smartphone"></span> 
                            Phone Number
                        </h5>
                        <p><a href="tel:+917676603982">+91-7676603982</a></p>
                        
                        <h5>
                            <span className="icon icon-basic-clock"></span> 
                            Working Hours
                        </h5>
                        <p>
                            09:00AM to 06:00PM
                        </p>
                        
					</div>
					
					{/* <!-- Contact form --> */}
					<Col className="col-12 col-lg-6">
						
                        <form id="contact-form" onSubmit={handleSubmit} ref={frm}>                             
                            
                            <Row>
                                <Col className="col-12 col-lg-6">
                                    <div className="form-group mt-2 mb-3">
                                        <input type="text" name="name" className="form-control field-name" placeholder="Name" />
                                    </div>
                                </Col>
                                <Col className="col-12 col-lg-6">
                                    <div className="form-group mt-2 mb-3">
                                        <input type="email" name="email" className="form-control field-email" placeholder="Email" />
                                    </div>
                                </Col>                                
                            </Row>
                            
                            <Row>
                                <Col className="col-12 col-lg-12">
                                    <div className="form-group mt-2 mb-3">
                                        <input type="text" name="subject" className="form-control field-subject" placeholder="Subject" />
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col className="col-12 col-lg-12">
                                    <div className="form-group mt-2 mb-3">
                                        <textarea name="message" rows="4" className="form-control field-message" placeholder="Message"></textarea>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row>
                            
                                <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    theme="dark"
                                    ref={this._reCaptchaRef}
                                    sitekey={TEST_SITE_KEY}
                                    onChange={this.handleChange}
                                    asyncScriptOnLoad={this.asyncScriptOnLoad}
                                />
                               
                            </Row> */}
                            
                            <Row>
                                <Col className="col-12 col-lg-12 mt-2">
                                    <button type="submit" id="contact-submit" name="send" className="btn" disabled={state.submitting}>Send Message</button>
                                </Col>
                            </Row>
                            
                        </form>
                        
                        {/* <!-- Submit Results --> */}
                        <div className="contact-form-result" ref={result}>
                            <h4>Thank you for the e-mail!</h4>
                            <p>Your message has already arrived! We'll contact you shortly.</p>
                        </div>
                        
					</Col>
					
				</Row>
				
			</Container>
			
		</section>
    );
}

export default ContactForm;