


import React from 'react';
 const GoogleMaps=()=>{
    return (
        <div className="google-map-code">
         <iframe title='d' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4253.819385846734!2d77.61924173235855!3d12.919764286597664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14fa1591a25f%3A0x485afa50faaa6b3d!2sWebeaz%20Technologies%20-%20Digital%20Marketing%20Services%2C%20Website%2C%20Mobile%20App%20%26%20eCommerce%20Development%20Company%20in%20Bangalore!5e0!3m2!1sen!2sin!4v1649926029748!5m2!1sen!2sin" width="1700" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
 }
 export default GoogleMaps;













// import React, { useState } from 'react';
// import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
// import PropTypes from 'prop-types';

// // Images
// import MapMarker from '~assets/images/map-marker-blue.png';

// // Google Maps API Key
// const apiKey = 'AIzaSyCvetqDPTxK7Hfgx5iE9P9LwmjXZTnwlXs';

// const GoogleMaps = GoogleApiWrapper({
//     apiKey,
// })(property => {
//     const { latitude, longitude, google, zoom, styles } = property;

//     const [state, setState] = useState({
//         showingInfoWindow: false,
//         activeMarker: {},
//         selecimport React, { useState } from 'react';
// import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
// import PropTypes from 'prop-types';

// // Images
// import MapMarker from '~assets/images/map-marker-blue.png';

// // Google Maps API Key
// const apiKey = 'AIzaSyCvetqDPTxK7Hfgx5iE9P9LwmjXZTnwlXs';

// const GoogleMaps = GoogleApiWrapper({
//     apiKey,
// })(property => {
//     const { latitude, longitude, google, zoom, styles } = property;

//     const [state, setState] = useState({
//         showingInfoWindow: false,
//         activeMarker: {},
//         selectedPlace: {}
//     });

//     const onMarkerClick = (props, marker) => setState({
//         selectedPlace: props,
//         activeMarker: marker,
//         showingInfoWindow: true
//     });

//     const onMapClicked = () => {
//         if (state.showingInfoWindow) {
//             setState({
//                 showingInfoWindow: false,
//                 activeMarker: null
//             });
//         }
//     };

//     const onInfoWindowClose = () => {
//         setState({
//             showingInfoWindow: false
//         });
//     };

//     return (
//         <Map
//             onClick={onMapClicked}
//             google={google}
//             className="map"
//             style={styles}
//             center={{ lat: latitude, lng: longitude }}
//             zoom={zoom}
//             height="400px"
//             width="100%"
//             id="google-map"
//         >
//             <Marker
//                 onClick={onMarkerClick}
//                 position={{ lat: latitude, lng: longitude }}
//                 icon={MapMarker}
//             />

//             <InfoWindow onClose={onInfoWindowClose} marker={state.activeMarker} visible={state.showingInfoWindow}>
//                 <div>
//                 <div id="content">
//            xport default GoogleMaps;             Vivamus sodales efficitur massa at rhoncus.
//                     </p>
//                     </div>
//                 </div>
//                 </div>
//             </InfoWindow>
//         </Map>
//     );
// });

// GoogleMaps.defaultProps = {
//     latitude: '37.800976',
//     longitude: '-122.428502',
//     zoom: 10,
//     styles: {
//         width: '100%',
//         height: '100%',
//         top: 0,
//         left: 0
//     }
// };

// GoogleMaps.propTypes = {
//     latitude: PropTypes.string,
//     longitude: PropTypes.string,
//     google: PropTypes.string,
//     zoom: PropTypes.number
// };

// export default GoogleMaps;
// tedPlace: {}
//     });

//     const onMarkerClick = (props, marker) => setState({
//         selectedPlace: props,
//         activeMarker: marker,
//         showingInfoWindow: true
//     });

//     const onxport default GoogleMaps;
//     const onInfoWindowClose = () => {
//         setState({
//             showingInfoWindow: false
//         });
//     };

//     return (
//         <Map
//             onClick={onMapClicked}
//             google={google}
//             className="map"
//             style={styles}
//             center={{ lat: latitude, lng: longitude }}
//             zoom={zoom}
//             height="400px"
//             width="100%"
//             id="google-map"
//         >
//             <Marker
//                 onClick={onMarkerClick}
//                 position={{ lat: latitude, lng: longitude }}
//                 icon={MapMarker}
//             />

//             <InfoWindow onClose={onInfoWindowClose} marker={state.activeMarker} visible={state.showingInfoWindow}>
//                 <div>
//                 <div id="content">
//                     <div id="siteNotice" />
//                     <h4 id="firstHeading" className="firstHeading">
//                     Naxos Main Office
//                     </h4>
//                     <div id="bodyContent">
//                     <p>
//                         Donec arcu nulla, semper et urna ac, laoreet porta.
//                         <br />
//                         Vivamus sodales efficitur massa at rhoncus.
//                     </p>
//                     </div>
//               GoogleMaps  </div>
//                 </div>
//             </InfoWindow>
//         </Map>
//     );
// });

// GoogleMaps.defaultProps = {
//     latitude: '37.800976',
//     longitude: '-122.428502',
//     zoom: 10,
//     styles: {
//         width: '100%',
//         height: '100%',
//         top: 0,
//         left: 0
//     }
// };

// GoogleMaps.propTypes = {
//     latitude: PropTypes.string,
//     longitude: PropTypes.string,
//     google: PropTypes.string,
//     zoom: PropTypes.number
// };

// export default GoogleMaps;
